import { Box, Flex, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import NotesList from "./NotesList";
import AddNote from "./AddNote";
import {  getWork } from "./apis/workApis";
import SpinnerCircle from "../utils/SpinnerCircle";
import moment from "moment";

const UpdateWorkForm = ({ parsed }) => {
  const [spinnerState, setSpinnerState] = useState(false);
  const [userWorkList, setUserWorkList] = useState([]);

  useEffect(() => {
    const fetchUserWork = async () => {
      try {
        setSpinnerState(true);
        const { data, err } = await getWork(
          {
            date: moment().utc().format("DD-MM-YYYY"),
          },
          parsed
        );
        if (!err) {
          setUserWorkList([...userWorkList, ...data]);
          setSpinnerState(false);
        }
        setSpinnerState(false);
      } catch (error) {
        console.error(error);
      }
    };

    fetchUserWork();
  }, []);


  return (
    <Flex
      p={4}
      bg="#101c44"
      borderRadius={"20px"}
      width="100%"
      flexDirection="column"
    >
      <Box>
        <Text fontSize="22px" fontWeight="800">
          Update Your Daily Report
        </Text>
      </Box>
      <Box
        display={"flex"}
        flexDirection="column"
        mt={"20px"}
        pl={"20px"}
        pr={"20px"}
        gap={"20px"}
      >
        <AddNote
          userWorkList={userWorkList}
          setUserWorkList={setUserWorkList}
          parsed={parsed}
        />
        {spinnerState ? (
          <SpinnerCircle />
        ) : (
          <NotesList notes={userWorkList} setNotes={setUserWorkList} parsed={parsed}/>
        )}
      </Box>
    </Flex>
  );
};

export default UpdateWorkForm;

import { Box, Flex, IconButton, Text } from "@chakra-ui/react";
import { MdRestoreFromTrash } from "react-icons/md";
import React from "react";

const Note = ({ id, text, date, handleDeleteNote }) => {
  return (
    <Box
      border={"solid"}
      borderWidth={"1px"}
      p={"0.5rem"}
      borderRadius={"10px"}
      minH={"100px"}
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"space-between"}
      overflow="hidden"
    >
      <Text fontWeight={600}>{text}</Text>
      <Flex justifyContent={"space-between"} alignItems={"flex-end"}>
        <Text fontSize={"12px"}>{date}</Text>
        <IconButton
          icon={<MdRestoreFromTrash size={"23px"} />}
          aria-label="Delete"
          size="xs"
          colorScheme={"whiteAlpha"}
          variant="ghost"
          onClick={() => handleDeleteNote(id)}
        />
      </Flex>
    </Box>
  );
};

export default Note;

import React from "react";
import Note from "./Note.jsx";
import { Grid, GridItem } from "@chakra-ui/react";
import { deleteMessage } from "./apis/workApis.js";

const NotesList = ({ notes,setNotes,parsed}) => {

  const deleteNote = async (messageId) => {
    try {
      const { data, err } = await deleteMessage(messageId, parsed);
      if (!err && data.success) {
        setNotes((prevNotes) =>
          prevNotes.filter((note) => note.id !== messageId)
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Grid gap={"0.5rem"}>
      {notes.map((note, index) => (
        <GridItem
          key={note.id}
          bgColor={index % 2 === 0 ? "" : "#192243"}
          borderRadius={"10px"}
        >
          <Note
            id={note.id}
            text={note.about_work}
            date={note.text_date}
            handleDeleteNote={deleteNote}
          />
        </GridItem>
      ))}
    </Grid>
  );
};

export default NotesList;

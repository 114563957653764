import React from "react";
import queryString from "query-string";
import { Box, Flex, Img, Text } from "@chakra-ui/react";
import carbonTrackLogo from "../assets/logo/CarbonTrackDarkLogo.svg";
import SessionList from "../components/SessionList";
import UpdateWorkForm from "../components/UpdateWorkForm";
import { version } from "../utils/common";
// import { getUser } from "../components/apis/userApis";
// import { toCamelCase } from "../utils/common";

const WorkDashboard = ({ location }) => {
  const parsed = queryString.parse(location.search);

  return (
    <Flex
      w="100%"
      bg="#0a1437"
      color="white"
      pb="20px"
      flexDirection="column"
      alignItems="center"
      minH={"100vh"}
    >
      <Box
        w={"85%"}
        mt={"40px"}
        mb={"20px"}
        p={5}
        bg="#101c44"
        borderRadius={"20px"}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Img src={carbonTrackLogo} alt="carbonTrack logo" width={"15%"} />
        <Flex direction={'column'} textAlign={'end'}>
          <Text fontSize="20px" fontWeight={800}>
            Welcome, {parsed.username}
          </Text>
          <Text fontSize="15px" fontWeight={800}>
            CarbonTrack v{version}
          </Text>
        </Flex>
      </Box>
      <Box
        w={"85%"}
        display={"flex"}
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        gap={"20px"}
      >
        <SessionList parsed={parsed} />
        <UpdateWorkForm parsed={parsed} />
      </Box>
    </Flex>
  );
};

export default WorkDashboard;

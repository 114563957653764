import axios from "axios";

/**
 * Get session
 * @function
 * @return {object}
 */
export const getUserSession = async (obj, authObj) => {
  try {
    const response = await axios.post(`/sessions/list`, {
      headers: { Authorization: `Bearer ${authObj.pass}` },
      obj,
    });
    return {
      data: response.data.message,
      err: null,
    };
  } catch (error) {
    return {
      data: null,
      err: error,
    };
  }
};

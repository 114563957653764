import axios from "axios";

/**
 * save message
 * @function
 * @return {object}
 */
export const saveUserWork = async (formObj, paramsObj) => {
  try {
    const response = await axios.post(`/workFile/save/message`, formObj, {
      params: paramsObj,
    });
    return {
      data: response.data,
      err: null,
    };
  } catch (error) {
    return {
      data: null,
      err: error,
    };
  }
};

/**
 * delete message by id
 * @function
 * @return {object}
 */
export const deleteMessage = async (messageId, paramsObj) => {
  try {
    const response = await axios.delete(
      `/workFile/deleteMesaage/${messageId}`,
      {
        headers: { Authorization: `Bearer ${paramsObj.pass}` },
      }
    );
    return {
      data: response.data,
      err: null,
    };
  } catch (error) {
    return {
      data: null,
      err: error,
    };
  }
};

/**
 * Get user work on date
 * @function
 * @return {object}
 */
export const getWork = async (obj, authObj) => {
  try {
    const response = await axios.post(`/workFile/getMessages`, {
      headers: { Authorization: `Bearer ${authObj.pass}` },
      obj,
    });
    return {
      data: response.data.message,
      err: null,
    };
  } catch (error) {
    return {
      data: null,
      err: error,
    };
  }
};

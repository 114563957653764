import React, { useEffect, useState } from "react";
import {
  Box,
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Text,
} from "@chakra-ui/react";
import SpinnerCircle from "../utils/SpinnerCircle";
import { dateToString, getDifferenceInMinutes } from "../utils/common";
import { getUserSession } from "../components/apis/sessionApis";

const SessionList = ({ parsed }) => {
  const [spinnerState, setSpinnerState] = useState(false);
  const [sessions, setSessions] = useState([]);

  useEffect(() => {
    fetchSessionList(parsed);
  }, []);

  const fetchSessionList = async (parsedData) => {
    try {
      setSpinnerState(true);
      const { data, err } = await getUserSession(
        {
          date: dateToString(new Date()),
        },
        parsedData
      );
      if (!err) {
        setSessions(data);
        setSpinnerState(false);
      }
      setSpinnerState(false);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Flex
      w={"100%"}
      p={4}
      bg="#101c44"
      borderRadius={"20px"}
      flexDirection="column"
    >
      <Box>
        <Text fontSize="22px" fontWeight="900">
          Your Session List
        </Text>
      </Box>
      <Box overflowX="auto" mb="20px" px="20px" mt="20px">
        <TableContainer h="90%" w="100%">
          <Table width="100%" variant="striped" colorScheme="blackAlpha">
            <Thead color="gray" textTransform="uppercase">
              <Tr textAlign="left">
                <Th pe="10px" py="10px" px="10px">
                  Start
                </Th>
                <Th pe="10px" py="10px" px="10px">
                  End
                </Th>
                <Th pe="10px" py="10px" px="10px">
                  Time
                </Th>
                <Th pe="10px" py="10px" px="10px">
                  IP
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {spinnerState ? (
                <Tr>
                  <Td colSpan={4} textAlign="center">
                    <SpinnerCircle />
                  </Td>
                </Tr>
              ) : (
                sessions.map((session, index) => (
                  <Tr key={index}>
                    <Td fontSize="sm" p="10px">
                      {new Date(session.start_session).toString()}
                    </Td>
                    <Td fontSize="sm" p="10px">
                      {session.end_session !== session.start_session
                        ? new Date(session.end_session).toString()
                        : "not ended"}
                    </Td>
                    <Td fontSize="sm" p="10px">
                      {getDifferenceInMinutes(
                        new Date(session.start_session),
                        new Date(session.end_session)
                      )}
                    </Td>
                    <Td fontSize="sm" p="10px">
                      {session.ipaddress ? session.ipaddress : "old"}
                    </Td>
                  </Tr>
                ))
              )}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
    </Flex>
  );
};

export default SessionList;

import moment from "moment";
const packageJson = require('../../package.json');

//return version number
export const version=packageJson.version;

export function getDifferenceInMinutes(date1, date2) {
  const diffInMs = Math.abs(date2 - date1);
  let timeInHM = convertMsToHM(diffInMs);
  return timeInHM;
}

export function padTo2Digits(num) {
  return num.toString().padStart(2, "0");
}

//convert ms to hm
export function convertMsToHM(milliseconds) {
  let seconds = Math.floor(milliseconds / 1000);
  let minutes = Math.floor(seconds / 60);
  let hours = Math.floor(minutes / 60);

  seconds = seconds % 60;
  // 👇️ if seconds are greater than 30, round minutes up (optional)
  minutes = seconds >= 30 ? minutes + 1 : minutes;

  minutes = minutes % 60;
  hours = hours % 24;

  return `${padTo2Digits(hours)}:${padTo2Digits(minutes)}`;
}

//convert date to string
export const dateToString = (now) => {
  return moment(now).utc().format("MM/DD/YYYY");
};

//convert date to string for work table only

export const dateToStringForWorkTable = (now) => {
  return moment(now).utc().format("DD/MM/YYYY");
};

export const toCamelCase = (str) => {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toUpperCase() : word.toUpperCase();
    })
    .replace(/\s+/g, " ");
};

import { Box, Button, Flex, Text, Textarea } from "@chakra-ui/react";
import moment from "moment";
import React, { useState } from "react";
import { saveUserWork } from "./apis/workApis";
import Swal from "sweetalert2";

const AddNote = ({ userWorkList, setUserWorkList, parsed }) => {
  const [noteText, setNoteText] = useState("");
  const characterLimit = 200;

  const handleChange = (event) => {
    if (characterLimit - event.target.value.length >= 0) {
      setNoteText(event.target.value);
    }
  };

  const handleSaveClick = () => {
    const date = moment().utc();
    if (noteText.trim().length > 0) {
      const newNote = {
        about_work: noteText,
        text_date: date.format("DD-MM-YYYY"),
        save_at: date,
      };
      saveUserWorks(newNote);
      setNoteText("");
    }
  };

  const saveUserWorks = async (newNote) => {
    if (parsed) {
      try {
        const { data, err } = await saveUserWork(newNote, {
          username: parsed.username,
          pass: parsed.pass,
        });
        if (!err) {
          if (data.success) {
            Swal.fire({
              text: "Message Saved",
            });
            setUserWorkList(data.message);
          }
        } else {
          Swal.fire({
            text: "Something went Wrong",
          });
          console.warn(err);
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  return (
    <Box>
      <Flex
        padding={2}
        border={"solid"}
        borderRadius={5}
        flexDirection={"column"}
        mb={3}
      >
        <Textarea
          value={noteText}
          onChange={handleChange}
          placeholder="Type to add a note..."
          size="sm"
          border={"none"}
          autoFocus={false}
        />
        <Flex justifyContent={"space-between"} mt={3}>
          <Text fontSize="12px" display={"flex"} alignItems={"flex-end"}>
            {characterLimit - noteText.length} Remaining
          </Text>
          <Button
            fontSize={"15px"}
            colorScheme="white"
            variant="outline"
            onClick={handleSaveClick}
          >
            Save
          </Button>
        </Flex>
      </Flex>
    </Box>
  );
};

export default AddNote;

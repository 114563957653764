import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import WorkDashboard from "./mainPage/WorkDashboard";
import { ChakraProvider } from "@chakra-ui/react";

const App = () => (
  <ChakraProvider>
    <Router>
      <Route path="/" component={WorkDashboard} />
    </Router>
  </ChakraProvider>
);

export default App;
